import * as echarts from 'echarts'

// 图表基础参数
let option = {
    tooltip: {
        trigger: 'axis',
        feature: {
            dataView: { show: true, readOnly: false },
            saveAsImage: {}
        },
        axisPointer: {
            type: 'shadow'
                // type: 'cross',
        }
    },
    legend: {
        data: []
    },
    title: [],
    grid: {
        left: '2%',
        right: '3.5%',
        bottom: '0%',
        // top: '',
        containLabel: true
    },
    xAxis: [{
        type: 'category',
        boundaryGap: false,
        axisTick: { show: true },
        data: []
    }],
    yAxis: [{
        type: 'value',
        name: '(单位：元)',
    }],
    series: [],
}

/**
 * @param {String} Id [绘制图表的id]
 * @param {String} ChartName [图表显示类型] 
 * ChartName => ['line','bar','pie','aline','abar']
 * @param {Object} ChartData [图表数据]
 * 
 */
export function canvasChart(Id, ChartName, ChartData, isTitle, Ytitle) {
    console.log(1)
    dispose(ChartName, ChartData, isTitle, Ytitle)
    canvas(Id)
}
/**
 * 绘制图表
 * */
export function canvas(Id) {
    let box = Id

    // 初始化
    box = echarts.init(document.getElementById(Id))

    // 清空已有图表
    box.clear()

    // 设置图表数据
    box.setOption(option)
    window.addEventListener("resize", function() {
        box.resize();
    });
}

/**
 * 数据整理成对应图表格式
 * @param {String} ChartName [图表显示类型] 
 * @param {Object} ChartData [图表数据] 
 * */
export function dispose(ChartName, ChartData, isTitle, Ytitle) {
    option.title = []
    option.legend.orient = 'horizontal'
    option.legend.left = 'center'
    option.legend.data = ChartData.names
    option.xAxis[0].boundaryGap = false
    option.xAxis[0].data = ChartData.x
    option.yAxis[0].name = Ytitle
    option.tooltip.trigger = 'axis'
    if (ChartName === 'pie') {
        option.legend.orient = 'vertical'
        option.legend.left = '10'
        option.series = []
        option.tooltip.trigger = 'item'
        option.xAxis[0].data = []
        option.yAxis[0].name = ''
        let series = [{
            type: ChartName,
            radius: ['50%', '70%'],
            center: ['50%', '50%'],
            label: {
                show: true,
                formatter: '{b}: {c} ({d}%)',
                color: 'auto'
            },
            emphasis: {
                label: {
                    show: true,
                    fontSize: '15',
                }
            },
            data: [],
        }]
        let sum = 0
        ChartData.series.forEach((item) => {
            let obj = {}
            obj.name = item.name
            let data = 0
            for (let i = 0; i < item.data.length; i++) {
                data = data + parseFloat(item.data[i])
            }
            obj.value = data.toFixed(2)
            sum = sum + data
            series[0].data.push(obj)
        })
        if (isTitle) {
            let title = {
                text: '总计' + '\n' + sum.toFixed(0),
                top: 'center',
                left: 'center'
            }
            option.title.push(title)
        }
        option.series = series
        return
    }
    if (ChartName === 'aline' || ChartName === 'abar') {
        let series = [{
            type: ChartName === 'aline' ? 'line' : 'bar',
            data: [],
        }]
        ChartData.series.forEach((item) => {
            series[0].data.push(item.data[0])
        })
        option.series = series
        if (ChartName === 'abar') {
            option.xAxis[0].boundaryGap = true
        }
        return
    }
    if (ChartName === 'bar') {
        option.xAxis[0].boundaryGap = true
    }
    ChartData.series.forEach(item => {
        item.type = ChartName
        item.barGap = 0
    });
    option.series = ChartData.series
}