<template>
    <!-- Main content -->
  <section class="content">
    <el-row>
      <el-col :span="4">
          <div class="box" :class="{'active':type==1}" @click="actStatis(1)">
            <div>{{total_user_times}}</div>
            <span>总参与用户数</span>
          </div>
      </el-col>
      <el-col :span="4">
        <div class="box" :class="{'active':type==2}" @click="actStatis(2)">
          <div>{{loot_times}}</div>
          <span>总夺宝次数</span>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="box" :class="{'active':type==3}" @click="actStatis(3)">
          <div>{{used_times}}</div>
          <span>总消耗次数</span>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="box" :class="{'active':type==4}" @click="actStatis(4)">
          <div>{{success_awarded_times}}</div>
          <span>总成功获奖次数</span>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="box" :class="{'active':type==5}" @click="actStatis(5)">
          <div>{{total_awarded_times}}</div>
          <span>已开奖次数</span>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="box" :class="{'active':type==6}" @click="actStatis(6)">
          <div>{{invite_times}}</div>
          <span>拉新用户数</span>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="box" :class="{'active':type==7}" @click="actStatis(7)">
          <div>{{click_times}}</div>
          <span>领取免单</span>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="box" :class="{'active':type==8}" @click="actStatis(8)">
          <div>{{click_free_order_times}}</div>
          <span>完成免单</span>
        </div>
      </el-col>
    </el-row>
    <div id="Source" style="min-height: 380px;max-width: 100%;"></div>
  </section>
</template>
<script>
    import * as api from '@/config/api'
    import * as canvas from '@/config/canvasEcharts'
    export default {
        name: 'MDMS',
        data() {
            return {
                invite_times: "",
                loot_times: "",
                success_awarded_times: "",
                total_awarded_times: "",
                total_user_times: "",
                used_times: "",
                click_times: "",
                click_free_order_times: "",
                type: 1,
            }
        },
        mounted() {
            this.actInfo()
            this.actStatis(5)
        },
        methods: {
            actInfo() {
                api.actInfo({}, res => {
                    this.invite_times = res.data.invite_times
                    this.loot_times = res.data.loot_times
                    this.success_awarded_times = res.data.success_awarded_times
                    this.total_awarded_times = res.data.total_awarded_times
                    this.total_user_times = res.data.total_user_times
                    this.used_times = res.data.used_times
                    this.click_times = res.data.click_times
                    this.click_free_order_times = res.data.click_free_order_times

                })
            },
            actStatis(type) {
                this.type = type
                api.actStatis({
                    type: type,
                }, res => {
                    let list = res.data.list
                    let type = 'line'
                    let data = {
                        data: []
                    }
                    let serie = {
                        series: []
                    }
                    let x = []
                    let names = []
                    console.log(2)
                    for (let i = 0; i < list.length; i++) {
                        x.push(list[i].create_time)
                        data.data.push(list[i].num)
                    }

                    serie.series.push(data)
                    serie.names = names
                    serie.x = x
                    canvas.canvasChart('Source', 'line', serie)
                })
            }
        }

    }
</script>
<style lang="scss">
    .box {
        div {
            font-size: 30px;
        }
        &.active {
            background-color: #409eff;
            color: #fff;
        }
    }
</style>